import { scaleQuantile } from 'd3-scale'
import { color } from 'd3-color'

export const DEFAULT_CONFIG = {
  target_utilisation: 75
}

export const DEFAULT_GROUP_BY = {
  building: 'Building',
  floor: 'Floor',
  zone: 'Zone',
  tag: 'Tag',
}

// Possible interval values for getZoneMetrics
export const INTERVALS = {
  PT5M: '5 minutes',
  PT15M: '15 minutes',
  PT1H: '1 hour',
  P1D: '1 day',
  P1W: '1 week',
  P1M: '1 month',
  P1Q: '1 quarter',
  INF: 'All time'
}

// Default locale
export const LOCALE = 'en-US'

// Value for determining if returntypes should be considered trustworthy because enough zones capacity has sufficient data
export const DEFAULT_MIN_CAPACITY_FRACTION_USED = .5

// Possible opening_hours values for getZoneMetrics
// The URL may contain a key and we'll look up the corresponding value
export const DEFAULT_OPENING_HOURS = {
  '24_7': '24/7',
  '24_5': 'Mo-Fr 00:00-24:00',
  'opening_hours': 'Mo-Fr 07:00-19:00',
  'office_hours': 'Mo-Fr 09:00-17:00',
  'core_hours': 'Mo-Th 10:00-16:00',
  'lunch_hours': 'Mo-Fr 12:00-13:00',
  'morning': 'Mo-Fr 09:00-12:00',
  'afternoon': 'Mo-Fr 14:00-17:00',
  'weekend': 'Sa-Su 10:00-16:00',
  'custom': 'Mo,Tu,We,Th,Fr 09:00-17:00'
}

/**
 *
 * This is a dictionary of all query parameters the application uses.
 * Since a new parameter can be supported for years, please think hard about the value (which shows up in the query) for
 * any new parameter. We want to ensure it's user-friendly, specific, readable and logical within the context of the application.
 *
 * Some parameters can be generic, since they have the same meaning throughout the app (e.g. stuff that appears in the global menu or header)
 * Others should be more specific, since it's only intended to be used within the context of a specific report or feature.
 *
 **/
export const QUERY_PARAMS = {
  AGGREGATION: 'aggregation',
  BOOKING_SOURCE: 'booking_source',
  CAPACITY: 'capacity',
  DATAFORMAT: 'dataformat',
  DATASOURCE: 'zone_layer',
  DATASOURCE_TYPE: 'datasource_type',
  ENDDATETIME: 'enddatetime',
  FILTER_COLUMNS: 'filter_columns', // reserved for react-table
  GROUP_BY: 'group',
  GROUP_COLUMNS: 'group_columns', // reserved for react-table
  HIDDEN_COLUMNS: 'hidden_columns', // reserved for react-table
  IDS: 'ids',
  INTERVAL: 'interval',
  OPENING_HOURS: 'opening_hours',
  OPENING_DAYS: 'opening_days',
  OPENING_START: 'opening_start',
  OPENING_END: 'opening_end',
  SHOW_COLUMNS: 'show_columns', // reserved for react-table
  SORT_COLUMNS: 'sort_columns', // reserved for react-table. NOTE - also used by Stacking Plan
  SPACE_LEVEL: 'space_level', // Portfolio Comparison
  STARTDATETIME: 'startdatetime',
  VISIBLE_COLUMNS: 'visible_columns', // reserved for react-table
  ZONE_METADATA: 'zone_metadata',
  ZONE_TAG: 'zone_group',
  SPACE_LAYER: 'space_type', // to select polygon level from Space (PIE-2813)

  // page-specific one-offs - move this out of here

  // stacking plan
  COLUMN_WIDTH: 'columnWidth',
  ROW_WIDTH: 'rowWidth',
  SHOW_ZONES_IN_GROUPS: 'showZonesInGroups',

  // portfolio
  COMPARISON_TIMEFRAME: 'comparison_timeframe',

  // utilization
  SHOW_ALL_GROUPS: 'show_all_groups',
  TABLE_SORTING: 'table_sorting',

  // deprecated - these are only used for toggles in feature flags
  CAMPUS: 'campus',
  FILTER_NO_CAPACITY: 'filter_no_capacity',
  IGNORE_MISSING_DATA: 'ignore_missing_data',
}

// By default, remember all space-related query parameters
export const DEFAULT_PERSISTENT_QUERY_PARAMS = [
  QUERY_PARAMS.CAPACITY,
  QUERY_PARAMS.IDS,
  QUERY_PARAMS.DATASOURCE,
  QUERY_PARAMS.SPACE_LAYER
]

// Colors from https://observablehq.com/@d3/color-schemes - d3-scale-chromatic sequential multi-hue GnBu (6)
export const DEFAULT_ROOM_SIZES = [
  { room_size: 'XS', min: 0, max: 1, color: "#ccebc5" },
  { room_size: 'S', min: 1, max: 2, color: "#a8ddb5" },
  { room_size: 'M', min: 2, max: 6, color: "#7bccc4" },
  { room_size: 'L', min: 6, max: 12, color: "#43a2ca" },
  { room_size: 'XL', min: 12, max: 500, color: "#0868ac" },
]

Object.freeze(DEFAULT_ROOM_SIZES)

// Default time frame
export const TIMEFRAME = [ 'pastSevenDays', 'lastWeek', 'pastTwoWeeks', 'lastMonth', 'pastSixWeeks', 'pastTwelveWeeks', 'pastSixMonths', 'pastYear', null, 'customRange', null, 'months' ]

// Default values for getUserData()
export const DEFAULT_USER_DATA = {
  favorite_urls: [],
  feature_flags: [],
  opening_hours: 'core_hours',
  unit_of_measure: 'metric',
  accuracy_detection: 'off',
  core_hours_min_utilization: 0.25,
  hvac_min_utilization: 0.05,
  prefered_building: '',
  data_color_palette: 'regular',
  homepage_cards: [
    {
      id: 'right_now',
      enabled: true,
    },
    {
      id: 'trend',
      enabled: true,
    },
    {
      id: 'patterns',
      enabled: true,
    },
    {
      id: 'portfolio_comparison',
      enabled: true,
    },
  ],
}

// Mapping for space type to group_zones parameter for getZoneMetrics
export const SPACE_FILTERS = {
  building: 'building_id',
  floor: 'floor_id',
  zone: 'id'
}

// This is a hard-coded list that is used for parsing opening hours
// TODO - replace it with index-based days so we can localize the weekdays
export const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const WEEKDAYS_SHORT = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
export const WORKDAYS = WEEKDAYS.slice(0, 5)
export const WORKDAYS_SHORT = WEEKDAYS_SHORT.slice(0, 5)
export const WEEKDAYS_DICT = new Map(WEEKDAYS_SHORT.map((d, i) => [d, WEEKDAYS[i]]))

// For parsing zone tags into "group:items" values
export const ZONE_TAG_DELIMITER = '.'
export const ZONE_TAG_NEIGHBORHOOD = 'neighborhood'

// Valid metadata keys that are user-selectable
export const ZONE_METADATA_FILTERS = ['has_bookingsource', 'desk', 'meeting_room']

export const ACCURACY_HIGH = 'ACCURACY_HIGH'
export const ACCURACY_MEDIUM = 'ACCURACY_MEDIUM'
export const ACCURACY_LOW = 'ACCURACY_LOW'

export const MAX_CAPACITY_FOR_OPPORTUNITY = 0.85
export const UNIFIED_DATA_SOURCE_NAME = 'unifiedlayer'

export const RETURNTYPE_LABELS = {
  capacity_mean: 'Capacity',
  capacity_fraction_used_mean: 'Percentage of zones capacity when excluding zones without data',
  count: 'People Count',
  count_mean: 'People Count (mean)',
  count_max: 'People Count (peak)',
  utilisation: 'Utilization Rate (%)',
  utilisation_mean: 'Utilization Rate (mean)',
  utilisation_max: 'Utilization Rate (peak)',
  frequency: 'Time in use (%)',
  frequency_mean: 'Time in use (mean)',
  frequency_max: 'Time in use (peak)'
}

export const DESIGN_MODES = new Set(['light', 'dark', 'system'])

export const DEFAULT_DOMAIN = [0, 1.2]

/**
  Defines what color schemes are available.
  useColorScheme() will provide the correct color scheme based on user preference
**/

export const COLOR_RANGES = {
  regular:      ['#FFF8B4', '#D4E8B1', '#90DFA6', '#74CFBA', '#75A9FF', '#D7B7FF'],
  hotandcold:   ['#007acc', '#75a9ff', '#d0c1fe', '#ffe3ff', '#f49cc2', '#e05269'],
  traffic:      ['#75c165', '#90DFA6', '#c6ee9e', '#fffbcb', '#f4ad7a', '#e05269'],
  blue_light:   ['#d8ffff', '#9de1ee', '#62c0e4', '#289eda', '#007acc' ],
  green_light:  ['#dcffce', '#bbeaaa', '#98d687', '#75c165', '#4ead43' ],
  green_dark:   ['#555555', '#587050', '#568c48', '#4da83d', '#3ac52b' ],
}

COLOR_RANGES.alternate = COLOR_RANGES.traffic

export const COLOR_SCHEMES = {
  regular: {
    colors: COLOR_RANGES.regular,
    noshows: '#FFF8B4',
    title: 'Regular',
    selectable: true,
  },
  hotandcold: {
    colors: COLOR_RANGES.hotandcold,
    noshows: '#007acc',
    title: 'Hot & cold',
    selectable: true,
  },
  alternate: {
    colors: COLOR_RANGES.traffic,
    noshows: 'rgba(212, 212, 212, 0.8)',
    title: 'Traffic lights',
    selectable: true,
  },
  alternate_alt: {
    colors: COLOR_RANGES.traffic.slice().reverse(),
    noshows: COLOR_RANGES.traffic.at(-1),
    title: 'Reverse Traffic lights',
    selectable: false,
  },
  single: {
    colors: COLOR_RANGES.blue_light,
    noshows: 'rgba(212, 212, 212, 0.8)',
    title: 'Single hue (blue)',
    selectable: false,
  },
  single_alt: {
    colors: COLOR_RANGES.green_light,
    noshows: 'rgba(212, 212, 212, 0.8)',
    title: 'Single hue (green)',
    selectable: false,
  },
}

export const HEADER_PATHS = {
  videoTutorials: {
    title: 'Video Tutorials',
    to: '/videos',
  },
  helpCenter: {
    title: 'Help Center',
    to: 'https://hubstarpresenceiq.zendesk.com/hc/en-us',
  },
  supportPortal: {
    title: 'Support Portal',
    to: 'https://hubstarpresenceiq.zendesk.com/hc/en-us/requests/new',
  },
  favorites: {
    title: 'Unnamed Favorites',
    to: '/user/favorites',
  },
  zones: {
    title: 'Zones',
    to: '/tools/zones',
  },
  upload: {
    title: 'Upload',
    to: '/tools/upload',
  },
  timemarkers: {
    title: 'Time Markers',
    to: '/tools/timemarkers',
  },
  uptime: {
    title: 'Uptime',
    to: '/tools/uptime',
  },
  preferences: {
    title: 'Personal preferences',
    to: '/user/settings/preferences',
  },
  buildingSettings: {
    title: 'Building settings',
    to: '/user/settings/building',
  },
  featureFlags: {
    title: 'Feature flags',
    to: '/user/settings/feature-flags',
  },
  design: {
    title: 'Design page',
    to: '/test/design',
  },
}

Object.keys(COLOR_SCHEMES).forEach(key => {
  let domain = key.includes('single') ? [0, 1] : DEFAULT_DOMAIN
  COLOR_SCHEMES[key].colors = COLOR_SCHEMES[key].colors.map(value => color(value))
  COLOR_SCHEMES[key].scale = scaleQuantile().domain(domain).range(COLOR_SCHEMES[key].colors)
})
