import { Icon, Text, Flex } from '@lonerooftop/kitt-ui'
import { logout } from '@lonerooftop/kitt-data'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { FaUser, FaQuestionCircle, FaExternalLinkAlt } from 'react-icons/fa'

import { buildMenuLink } from './menu'
import { Tooltip } from '../tooltip'
import { DropdownMenu } from '../dropdown'
import { HEADER_PATHS } from '../../constants'
import { useDarkmode } from '../../hooks/use-preferences'
import { useConfigurableFeatureFlags } from '../../hooks/use-preferences'
import { captureUserLogout, reset } from '../../utils/analytics'
import features from '../../utils/features'

const TOGGLE = {
  dark: 'light',
  light: 'dark'
}

export const HelpMenu = () => {
  const navigate = useNavigate()

  const helpMenuItems = [
    {
      title: <MenuLinkItem path={HEADER_PATHS.videoTutorials.to} title={HEADER_PATHS.videoTutorials.title} />,
      key: 'video-tutorials',
      onClick: () => navigate(HEADER_PATHS.videoTutorials.to),
    },
    {
      title: <MenuExternalLinkItem title={HEADER_PATHS.helpCenter.title} />,
      key: 'help-center',
      onClick: () => window.open(HEADER_PATHS.helpCenter.to, '_blank', 'noopener,noreferrer'),
    },
    {
      title: <MenuExternalLinkItem title={HEADER_PATHS.supportPortal.title} />,
      key: 'support-portal',
      onClick: () => window.open(HEADER_PATHS.supportPortal.to, '_blank', 'noopener,noreferrer'),
    }
  ]

  return (
    <HeaderMenu data-test-id='help-menu' title='Help' menuItems={helpMenuItems} icon={<FaQuestionCircle />} />
  )
}

export const AdminMenu = () => {
  const navigate = useNavigate()
  const [ query ] = useSearchParams()
  const hasConfigurableFeatureFlags = useConfigurableFeatureFlags()
  const [ darkmode = 'light', setDarkmode ] = useDarkmode()

  const adminMenuItems = [
    {
      title: <MenuLinkItem path={HEADER_PATHS.favorites.to} title={HEADER_PATHS.favorites.title} />,
      key: 'favorites',
      onClick: () => navigate(HEADER_PATHS.favorites.to),
    },
    {
      title: <MenuLinkItem path={HEADER_PATHS.zones.to} title={HEADER_PATHS.zones.title} />,
      key: 'zones',
      onClick: () => navigate(HEADER_PATHS.zones.to),
    },
    {
      title: <MenuLinkItem path={HEADER_PATHS.upload.to} title={HEADER_PATHS.upload.title} />,
      key: 'upload',
      onClick: () => navigate(HEADER_PATHS.upload.to),
      showItem: features.enabled('upload-data')
    },
    {
      title: <MenuLinkItem path={HEADER_PATHS.timemarkers.to} title={HEADER_PATHS.timemarkers.title} />,
      key: 'time-markers',
      onClick: () => navigate(HEADER_PATHS.timemarkers.to),
    },
    {
      title: <MenuLinkItem path={HEADER_PATHS.uptime.to} title={HEADER_PATHS.uptime.title} />,
      key: 'uptime',
      onClick: () => navigate(buildMenuLink({ to: HEADER_PATHS.uptime.to }, query)),
    },
    {
      title: `Switch to ${TOGGLE[darkmode]} mode`,
      key: 'light-dark-mode',
      onClick: () => setDarkmode(TOGGLE[darkmode]),
    },
    null,
    {
      title: <MenuLinkItem path={HEADER_PATHS.preferences.to} title={HEADER_PATHS.preferences.title} />,
      key: 'personal-preferences',
      onClick: () => navigate(HEADER_PATHS.preferences.to),
    },
    {
      title: <MenuLinkItem path={HEADER_PATHS.buildingSettings.to} title={HEADER_PATHS.buildingSettings.title} />,
      key: 'building-settings',
      onClick: () => navigate(HEADER_PATHS.buildingSettings.to),
    },
    {
      title: <MenuLinkItem path={HEADER_PATHS.featureFlags.to} title={HEADER_PATHS.featureFlags.title} />,
      key: 'feature-flags',
      onClick: () => navigate(HEADER_PATHS.featureFlags.to),
      showItem: hasConfigurableFeatureFlags
    },
    {
      title: <MenuLinkItem path={HEADER_PATHS.design.to} title={HEADER_PATHS.design.title} />,
      key: 'design-page',
      onClick: () => navigate(HEADER_PATHS.design.to),
      showItem: hasConfigurableFeatureFlags
    },
    {
      title: 'Logout',
      key: 'logout',
      onClick: async () => {
        try {
          // Wait for all async posthog stuff to resolve
          await captureUserLogout()
          await reset()
          window.localStorage.clear()
          // Logout does a reload immediately after
          logout()
        } catch (error) {
          logout()
        }
      },
    }
  ]

  return (
    <HeaderMenu data-test-id='admin-menu' title='Admin' menuItems={adminMenuItems} icon={<FaUser />} />
  )
}

const MenuLinkItem = (props) => {
  const { path, title } = props
  const { pathname } = useLocation()

  return <Text className={pathname === path ? 'header-menu-current-page' : ''}>{title}</Text>
}

const MenuExternalLinkItem = (props) => {
  const { title } = props

  return (
    <Flex width={100} justifyContent='space-between'>
      <Text pr={3}>{title}</Text>
      <FaExternalLinkAlt />
    </Flex>
  )
}

const HeaderMenu = (props) => {
  const { title, menuItems, icon, 'data-test-id': dataTestId } = props
  const activeMenuItems = menuItems.filter(item => item === null || !('showItem' in item) || item.showItem)

  return (
    <Tooltip as='span' title={title}>
      <DropdownMenu
        data-test-id={dataTestId}
        aria-label={`${title} menu`}
        items={activeMenuItems.map(item => item ? { title: item.title, key: item.key } : null)}
        placement='bottom-end'
        transparent
        indicateSelected={false}
        forceTitle
        onChange={(key) => activeMenuItems.find(item => item?.key === key).onClick()}
        title={
          <Icon display='flex'>
            {icon}
          </Icon>
        }
      />
    </Tooltip>
  )
}
