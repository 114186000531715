import { useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { FaLock, FaClock, FaExternalLinkAlt } from 'react-icons/fa'
import { TextTooltip } from '../tooltip'

import { usePersistentScrollPosition } from '../../hooks/use-persistent-scroll-position'
import { useStore, useSpaceHierarchy } from '../../hooks/use-store'
import { usePreferences } from '../../hooks/use-preferences'

import { createDuration } from '../../utils/date-utils'
import features from '../../utils/features'

import { DEFAULT_PERSISTENT_QUERY_PARAMS } from '../../constants'
import MENU from '../../menu.json'

export function Menu () {
  let { pathname, search } = useLocation()
  let [ query ] = useSearchParams()
  const { preferences: { favorite_urls } } = usePreferences()
  let [expanded, setExpanded] = useState()
  let ref = usePersistentScrollPosition({
    id: 'menu-scroll-position',
    key: pathname
  })
  let [minDate, maxDate] = useStore(2).getMinMaxDate(useSpaceHierarchy().ids)
  let options = {
    pathname,
    search,
    query,
    days: createDuration(minDate, maxDate).as('days'),
    expanded,
    setExpanded,
    favorites: favorite_urls
  }

  return (
    <nav className='app-menu' ref={ref}>
      {MENU.main.map(item => renderMenu(item, options))}
    </nav>
  )
}

function renderMenu (item, options) {
  let { days, pathname, search, query } = options
  let menuId = `menu-${(item?.title ?? item?.to ?? item?.href)}`
  let { disabled, beta, hidden } = getMenuItemStatus(item)
  let enoughData = item.minimumDays ? days >= item.minimumDays : true
  if (hidden) {
    return null
  }

  if (item.to) {
    let current = item.to === (item.matchExactPath ? pathname.concat(search) : pathname) ? 'page' : undefined
    return (
      <Link
        className='menu-item'
        key={menuId}
        aria-current={current}
        disabled={disabled}
        to={disabled ? '/forbidden' : buildMenuLink(item, query)}
      >
        {item.title}
        {beta && <MenuIcon>BETA</MenuIcon>}
        {disabled && <MenuIcon title='This report is not available. Please mail us at PresenceIQSupport@HubStar.com to get access.'><FaLock /></MenuIcon>}
        {!enoughData && <MenuIcon title='Not enough data is available yet. Check back later.'><FaClock /></MenuIcon>}
      </Link>
    )
  } else if (item.href) {
    return (
      <a
        key={menuId}
        className='menu-item'
        href={item.href}
        rel='noreferrer noopener'
        target='_blank'
      >
        {item.title}
        <MenuIcon>
          <FaExternalLinkAlt />
        </MenuIcon>
      </a>
    )
  } else if (item.items) {
    if (item.items.every(item => getMenuItemStatus(item).hidden)) {
      return null
    }

    if (item.title) {
      return renderMenuItems(item, options, menuId)
    } else {
      return item.items.map(item => renderMenu(item, options))
    }
  } else if (item.title === 'Favorites') {
    const namedFavorites = options.favorites.filter(favorite => !!favorite.name)
    if (!namedFavorites.length) {
      return null
    }
    const favoriteItems = namedFavorites.map(favorite => {
      return {
        to: favorite.to,
        title: favorite.name,
        persistQuery: [],
        matchExactPath: true
      }
    })
    return renderMenuItems({ title: item.title, items: favoriteItems }, options, menuId)
  }

  console.warn(item)
  return null
}

const renderMenuItems = (item, options, menuId) => {
  const { expanded, setExpanded } = options
  const isExpanded = expanded === menuId
  return (
    <div
      key={menuId}
      aria-expanded={isExpanded ? 'expanded' : 'collapsed'}
      className='menu-section'
    >
      <div className='menu-header' onClick={(e) => {
        e.preventDefault()
        if (isExpanded) {
          setExpanded(null)
        } else {
          setExpanded(menuId)
        }
      }}>
        {item.title}
      </div>
      <div className='foldable-menu-items'>
        <div className='menu-items'>
          {item.items.map(item => renderMenu(item, options))}
        </div>
      </div>
    </div>
  )
}

function MenuIcon ({ children, title }) {
  return (
    <small className='font-normal font-size-9 float-right detail'>
      <TextTooltip title={title} placement='top'>
        {children}
      </TextTooltip>
    </small>
  )
}

export function buildMenuLink (item, query) {
  let keep = item?.persistQuery ?? DEFAULT_PERSISTENT_QUERY_PARAMS
  if (keep.length === 0 || query.size === 0 || keep.every(key => !query.has(key))) {
    return item.to
  }

  let output = new URLSearchParams(query)
  for (let key of output.keys()) {
    if (!keep.includes(key)) {
      output.delete(key)
    }
  }

  return `${item.to}?${output.toString()}`
}

export function getPathnameStatus (pathname) {
  let item = findMenuItemByPath(pathname)
  if (!item) {
    // not all paths have menu entries (e.g. user settings)
    return { disabled: false, beta: false, hidden: false }
  }

  return getMenuItemStatus(item)
}

function getMenuItemStatus (item) {
  let {beta, featureFlag, hidden} = item
  let enabled = featureFlag ? features.enabled(featureFlag) : true

  return {
    disabled: Boolean(beta && !enabled),
    beta: Boolean(beta),
    hidden: Boolean(hidden && !enabled)
  }
}

export function findMenuItemByPath (to, items = MENU.main) {
  for (let item of items) {
    if (item.to === to) {
      return item
    } else if (item.items) {
      const found = findMenuItemByPath(to, item.items)
      if (found) return found
    }
  }
  return null
}

export function findSectionByPath (to, items = MENU.main) {
  for (let item of items) {
    if (!item.items) {
      continue
    }

    if (item.items.some(item => item.to === to)) {
      return [item]
    }

    const found = findSectionByPath(to, item.items)
    if (found) {
      return [item, ...found]
    }
  }
  return null
}
