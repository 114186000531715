import {
  useCapacity,
  useStartDate,
  useEndDate,
  useTags,
  useMetadata,
} from './use-page-settings'
import { useActiveDataSource } from './use-active-data-source'
import { useActiveSpaceLayer } from './use-active-space-layer'
import { useStore, useSpaces } from './use-store'

import { isUnifiedZone } from '../utils/zone-utils'
import { UNIFIED_DATA_SOURCE_NAME } from '../constants'
import memo from 'memoize-one'

/** Returns the most specifically selected zones **/
export function useActiveZones () {
  let store = useStore()
  let selected = useSpaces()
  let startdatetime = useStartDate()
  let enddatetime = useEndDate()
  return getActiveZones(store, selected, startdatetime, enddatetime)
}

let getActiveZones = memo((store, selected, startdatetime, enddatetime) => {
  console.time('useActiveZones')
  let { campuses, buildings, floors, zones } = selected
  let result = []

  if (zones.length > 0) {
    result = zones
  } else if (floors.length > 0) {
    result = floors.flatMap(
      floor => store.zones(floor.id, startdatetime, enddatetime)
    )
  } else if (buildings.length > 0) {
    result = buildings.flatMap(
      building => store.zones(building.id, startdatetime, enddatetime)
    )
  } else if (campuses.length > 0) {
    result = campuses.flatMap(
      campus => store.zones(campus.id, startdatetime, enddatetime)
    )
  }

  console.timeEnd('useActiveZones')
  return result
})

export function useActiveZonesByDataSource () {
  let zones = useActiveZones()
  let capacity = useCapacity()
  let { activeDataSource, activeDataSourceTypes } = useActiveDataSource()
  return getActiveZonesByDataSource(zones, activeDataSource, activeDataSourceTypes, capacity)
}

export let getActiveZonesByDataSource = memo((zones, activeDataSource, activeDataSourceTypes, capacity) => {
  console.time('useActiveZonesByDataSource')
  let result = zones.filter(zone => {
    let hasDataSource = activeDataSource === UNIFIED_DATA_SOURCE_NAME
      ? isUnifiedZone(zone)
      : activeDataSource ? zone.layer_name === activeDataSource : true

    let hasDataSourceType = (Array.isArray(activeDataSourceTypes) && activeDataSourceTypes.length > 0)
      ? activeDataSourceTypes.includes(zone.datasource.datasource_type)
      : true

    let hasCapacity = zone.capacity.has(capacity)

    return hasDataSource && hasDataSourceType && hasCapacity
  })
  console.timeEnd('useActiveZonesByDataSource')
  return result
})

export function useActiveZonesByDataSourceSpaceLayer () {
  let zones = useActiveZonesByDataSource()
  let spaceLayer = useActiveSpaceLayer()
  return getActiveZonesByDataSourceSpaceLayer(zones, spaceLayer)
}

export let getActiveZonesByDataSourceSpaceLayer = memo((zones, spaceLayer) => {
  console.time('useActiveZonesByDataSourceSpaceLayer')
  if (!!spaceLayer) {
    return zones.filter(zone => zone.metadata?.space_type === spaceLayer)
  }

  console.timeEnd('useActiveZonesByDataSourceSpaceLayer')
  return zones
})

export function useActiveZonesByDataSourceMetaData () {
  let zones = useActiveZonesByDataSource()
  let metadata = useMetadata()
  return getActiveZonesByDataSourceMetaData(zones, metadata)
}

let getActiveZonesByDataSourceMetaData = memo((zones, metadata) => {
  if (metadata.length === 0) {
    return zones
  }

  console.time('useActiveZonesByDataSourceMetaData')
  if (metadata.length > 0) {
    zones = zones.filter(
      zone => metadata.every(key => zone.metadata?.[key] ?? false)
    )
  }

  console.timeEnd('useActiveZonesByDataSourceMetaData')
  return zones
})

export function useActiveZonesByDataSourceSpaceLayerMetaData () {
  let zones = useActiveZonesByDataSourceSpaceLayer()
  let metadata = useMetadata()
  return getActiveZonesByDataSourceSpaceLayerMetaData(zones, metadata)
}

export let getActiveZonesByDataSourceSpaceLayerMetaData = memo((zones, metadata) => {
  if (metadata.length === 0) {
    return zones
  }

  console.time('useActiveZonesByDataSourceSpaceLayerMetaData')
  if (metadata.length > 0) {
    zones = zones.filter(
      zone => metadata.every(key => zone.metadata?.[key] ?? false)
    )
  }

  console.timeEnd('useActiveZonesByDataSourceSpaceLayerMetaData')
  return zones
})

export function useActiveZonesByDataSourceSpaceLayerTagsMetaData () {
  let zones = useActiveZonesByDataSourceSpaceLayer()
  let metadata = useMetadata()
  let tags = useTags()
  return getActiveZonesByDataSourceSpaceLayerTagsMetaData(zones, metadata, tags)
}

let getActiveZonesByDataSourceSpaceLayerTagsMetaData = memo((zones, metadata, tags) => {
  if (metadata.length === 0 && tags.length === 0) {
    return zones
  }

  console.time('useActiveZonesByDataSourceSpaceLayerTagsMetaData')
  if (metadata.length > 0) {
    zones = zones.filter(
      zone => metadata.every(key => zone.metadata?.[key] ?? false)
    )
  }

  if (tags.length > 0) {
    zones = zones.filter(
      zone => zone.tags.some(tag => tags.includes(tag))
    )
  }

  console.timeEnd('useActiveZonesByDataSourceSpaceLayerTagsMetaData')
  return zones
})

export function useActiveZonesByDataSourceTagsMetaData () {
  let zones = useActiveZonesByDataSource()
  let metadata = useMetadata()
  let tags = useTags()
  return getActiveZonesByDataSourceTagsMetaData(zones, metadata, tags)
}

let getActiveZonesByDataSourceTagsMetaData = memo((zones, metadata, tags) => {
  if (metadata.length === 0 && tags.length === 0) {
    return zones
  }

  console.time('useActiveZonesByDataSourceTagsMetaData')
  if (metadata.length > 0) {
    zones = zones.filter(
      zone => metadata.every(key => zone.metadata?.[key] ?? false)
    )
  }

  if (tags.length > 0) {
    zones = zones.filter(
      zone => zone.tags.some(tag => tags.includes(tag))
    )
  }

  console.timeEnd('useActiveZonesByDataSourceTagsMetaData')
  return zones
})

export function useActiveZonesByTagsMetaData () {
  let zones = useActiveZones()
  let metadata = useMetadata()
  let tags = useTags()
  return getActiveZonesByTagsMetaData(zones, metadata, tags)
}

let getActiveZonesByTagsMetaData = memo((zones, metadata, tags) => {
  if (metadata.length === 0 && tags.length === 0) {
    return zones
  }

  console.time('useActiveZonesByTagsMetaData')
  if (metadata.length > 0) {
    zones = zones.filter(
      zone => metadata.every(key => zone.metadata?.[key] ?? false)
    )
  }

  if (tags.length > 0) {
    zones = zones.filter(
      zone => zone.tags.some(tag => tags.includes(tag))
    )
  }

  console.timeEnd('useActiveZonesByTagsMetaData')
  return zones
})
