import isSameYear from 'date-fns/isSameYear'
import setMonth from 'date-fns/setMonth'
import setYear from 'date-fns/setYear'
import startOfMonth from 'date-fns/startOfMonth'
import startOfYear from 'date-fns/startOfYear'

import { useEffect, useState } from 'react'
import { Box, SmallDetail, Icon } from '@lonerooftop/kitt-ui'
import { CaptionNavigation, DayPicker, useDayPicker, useNavigation } from 'react-day-picker'
import { FaCaretDown } from 'react-icons/fa'

import { DropdownMenu } from '../dropdown'
import { Button } from '../button'
import { DateTimeFormat } from '../datetime'
import { CardHeader, CardContent } from '../cards'
import {
  Popover,
  PopoverTrigger,
  PopoverHeading,
  PopoverContent,
  PopoverFooter,
  PopoverCancel,
  PopoverConfirm,
} from '../popover'

const format = { year: 'numeric', month: 'short', day: 'numeric' }
const dayFormat = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' }

/**
 * Pure UI component that provides date range picker
 *
 **/
export function SelectDateRangePicker (props) {
  let {
    minDate,
    maxDate,
    fromDate,
    toDate,
    setFromTo,
    buttonClass,
    isDatePickerOpen,
    setIsDatePickerOpen,
    trigger,
  } = props
  let [range, setRange] = useState({ from: fromDate, to: toDate })

  // make this a controlled component so we can update the dates
  // when hiding the calendar
  const [ isOpen, setIsOpen ] = useState(false)
  const visible = isDatePickerOpen || isOpen
  const setVisible = setIsDatePickerOpen || setIsOpen

  // we don't use onSelect so we can implement our own addToRange logic
  let onDayClick = (day, activeModifiers, e) => {
    const nextRange = addToRange(day, range)
    setRange(nextRange)
  }

  let onWeekNumberClick = (weekNumber, days) => {
    setRange({from: days[0], to: days[days.length - 1] })
  }

  let isSingleDay = (toDate - fromDate) < 86400000 // 26 hours in milliseconds

  const triggerElement = trigger || (
    <button
      className={`${buttonClass} button button-date-picker${visible ? ' button-active' : ''}`}
      style={{ minWidth: '16rem' }}
      onClick={() => setVisible(true)}
    >
      {isSingleDay
        ? <DateTimeFormat date={fromDate} format={dayFormat} />
        :
        <>
          <DateTimeFormat date={fromDate} format={format} />
          <SmallDetail> – </SmallDetail>
          <DateTimeFormat date={toDate} format={format} />
        </>}
      <Icon ml={2} fontSize={8}>
        <FaCaretDown className={visible ? 'rotatable rotate-180' : 'rotatable'} />
      </Icon>
    </button>
  )

  return (
    <Popover open={visible} onOpenChange={() => setVisible(false)}>
      <PopoverTrigger asChild>
        {triggerElement}
      </PopoverTrigger>
      <PopoverContent data-test-id='select-date-range-picker' variant='primary'>
        <CardHeader display='flex' alignItems='center'>
          <PopoverHeading flex={1}>Date picker</PopoverHeading>
        </CardHeader>
        <CardContent>
          <DayPicker
            components={{
              Caption,
              CaptionLabel,
              IconDropdown,
              WeekNumber
            }}
            defaultMonth={fromDate}
            fixedWeeks
            fromDate={minDate}
            initialFocus
            mode='range'
            onDayClick={onDayClick}
            onWeekNumberClick={onWeekNumberClick}
            selected={range}
            showOutsideDays
            showWeekNumber
            toDate={maxDate}
            weekStartsOn={1}
            classNames={{
              button: 'button',
            }}
            modifiersClassNames={{
              hidden: 'button-transparent',
              selected: 'button-active',
              today: 'button-primary'
            }}
          />
          <Box
            align='center'
            flex={1}
            fontSize={7}
            fontWeight='bold'
            p={2}
            mt={2}
          >
            {range.from
              ? <DateTimeFormat date={range.from} format={format} />
              : ''}
            –
            {range.to
              ? <DateTimeFormat date={range.to} format={format} />
              : 'select end date'}
          </Box>
        </CardContent>
      <PopoverFooter mr={3} mb={3}>
        <PopoverCancel onCancel={() => {
            setRange({ from: fromDate, to: toDate })
            setVisible(false)
          }} />
        <PopoverConfirm onConfirm={() => {
            setFromTo(range)
            setVisible(false)
          }} label='Apply time frame' />
      </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

function Caption ({ id, displayMonth }) {
  const { classNames, styles } = useDayPicker()
  return (
    <Box
      className={classNames.caption}
      style={styles.caption}
      align='center'
      position='relative'
    >
      <CaptionNavigation displayMonth={displayMonth} id={id} />
      <CaptionDropdowns displayMonth={displayMonth} id={id} />
    </Box>
  )
}

function CaptionLabel () {
  return null
}

function IconDropdown () {
  return null
}

/**
 * Render the week number element. If `onWeekNumberClick` is passed to DayPicker, it
 * renders a button, otherwise a span element.
 *
 * Originally adapted from https://github.com/gpbl/react-day-picker/blob/v8.5.1/packages/react-day-picker/src/components/WeekNumber/WeekNumber.tsx
 */
function WeekNumber (props) {
  const { number: weekNumber, dates } = props
  const {
    fromDate,
    toDate,
    onWeekNumberClick,
    styles,
    classNames,
    locale,
    labels: { labelWeekNumber },
    formatters: { formatWeekNumber }
  } = useDayPicker()

  const content = formatWeekNumber(Number(weekNumber), { locale })

  if (!onWeekNumberClick) {
    return (
      <span className={classNames.weeknumber} style={styles.weeknumber}>
        {content}
      </span>
    )
  }

  const label = labelWeekNumber(Number(weekNumber), { locale })

  const handleClick = function (e) {
    onWeekNumberClick(
      weekNumber,
      // OURS - only select dates inside our range
      dates.filter(date => date >= fromDate && date <= toDate),
      e
    )
  }

  return (
    <Button
      name='week-number'
      type='button'
      aria-label={label}
      className={classNames.weeknumber}
      style={styles.weeknumber}
      onClick={handleClick}
      // OURS - disable weeks that are out of our range
      disabled={dates.every(date => date < fromDate || date > toDate)}
    >
      {content}
    </Button>
  );
}

/**
 * Add a day to an existing range.
 * Reset the range if from & to are already set
 */
function addToRange(day, range) {
  const { from, to } = range || {}

  switch (true) {
    case !from:
      return { from: day, to }
    // allow selecting ranges backwards in time
    case !to && day < from:
      return { from: day, to: from }
    case !to:
      return { from, to: day }
    default:
      return { from: day }
  }
}

function CaptionDropdowns(props) {
  const { classNames, styles } = useDayPicker()
  const { goToMonth } = useNavigation()

  const handleMonthChange = (newMonth) => {
    goToMonth(newMonth)
  }

  return (
    <div
      className={classNames.caption_dropdowns}
      style={styles.caption_dropdowns}
    >
      <MonthsDropdown
        onChange={handleMonthChange}
        displayMonth={props.displayMonth}
      />
      <YearsDropdown
        onChange={handleMonthChange}
        displayMonth={props.displayMonth}
      />
    </div>
  )
}


/** Render the dropdown to navigate between months. */
export function MonthsDropdown(props) {
  const {
    fromDate,
    toDate,
    locale,
    formatters: { formatMonthCaption },
    labels: { labelMonthDropdown }
  } = useDayPicker()

  // when displayMonth is changed to an out-of-range month, reset it to the earliest month
  let value = props.displayMonth.getMonth()
  let setFromMonth = isSameYear(props.displayMonth, fromDate) && value < fromDate.getMonth()
  let setToMonth = isSameYear(props.displayMonth, toDate) && value > toDate.getMonth()
  let onChange = props.onChange
  useEffect(() => {
    if (setFromMonth) {
      onChange(fromDate)
    }

    if (setToMonth) {
      onChange(toDate)
    }
  }, [ onChange, fromDate, toDate, setFromMonth, setToMonth ])

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate) return <></>
  if (!toDate) return <></>

  const dropdownMonths = []

  let startMonth = 0
  let endMonth = 11
  let date = startOfMonth(props.displayMonth)

  if (isSameYear(props.displayMonth, fromDate) || isSameYear(fromDate, toDate)) {
    startMonth = fromDate.getMonth()
  }

  if (isSameYear(props.displayMonth, toDate) || isSameYear(fromDate, toDate)) {
    endMonth = toDate.getMonth()
  }

  for (let month = startMonth; month <= endMonth; month++) {
    dropdownMonths.push(setMonth(date, month))
  }

  const handleChange = (e) => {
    const selectedMonth = Number(e.target.value)
    const newMonth = setMonth(startOfMonth(props.displayMonth), selectedMonth)
    props.onChange(newMonth)
  }

  return (
    <DropdownMenu
      title={formatMonthCaption(props.displayMonth, { locale })}
      ariaLabel={labelMonthDropdown()}
      indicateSelected={false}
      items={dropdownMonths.map(month => {
        return {
          key: month.getMonth(),
          title: formatMonthCaption(month, { locale }),
          onChange: () => handleChange({ target: { value: month.getMonth() } })
        }
      })}
    />
  )
}

/**
 * Copy/paste from react-day-picker/src/components/YearsDropdown v8.3.7
 */
export function YearsDropdown(props) {
  const { displayMonth } = props
  const {
    fromDate,
    toDate,
    locale,
    formatters: { formatYearCaption },
    labels: { labelYearDropdown }
  } = useDayPicker()

  const years = []

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate) return <></>
  if (!toDate) return <></>

  const fromYear = fromDate.getFullYear()
  const toYear = toDate.getFullYear()
  for (let year = fromYear; year <= toYear; year++) {
    years.push(setYear(startOfYear(new Date()), year))
  }

  const handleChange = (e) => {
    const newMonth = setYear(
      startOfMonth(displayMonth),
      Number(e.target.value)
    )
    props.onChange(newMonth)
  }

  return (
    <DropdownMenu
      title={formatYearCaption(displayMonth, { locale })}
      ariaLabel={labelYearDropdown()}
      indicateSelected={false}
      items={years.map(year => {
        return {
          key: year.getFullYear(),
          title: formatYearCaption(year, { locale }),
          onChange: () => handleChange({ target: { value: year.getFullYear() } })
        }
      })}
    />
  )
}
