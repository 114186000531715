import { DateTime } from 'luxon'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { Button } from '../components/button'

import { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { usePreferences } from '../hooks/use-preferences'

import { timeframes, findTimeframe } from '../utils/timeframes'

import { Required } from '../components/form'
import { Box, Flex, Text } from '@lonerooftop/kitt-ui'
import { CardContent, CardHeader } from '../components/cards'
import { Message } from '../components/message'
import {
  Dialog,
  DialogTrigger,
  DialogHeading,
  DialogContent,
  DialogFooter,
  DialogCancel,
  DialogConfirm,
} from '../components/dialog'
import {
  MoreSettings,
  MoreSettingsItem,
  MoreSettingsItemLabel,
  MoreSettingsItemControl,
} from '../components/app/header'

function useFavoriteUrl () {
  let location = useLocation()
  let url = location.pathname + location.search
  let { dispatch, preferences: { favorite_urls } } = usePreferences()
  let isSaved = favorite_urls.some(f => f.to === url)
  let { searchParams } = new URL(window.location.origin + url)
  let timeframe = findTimeframe(
    DateTime.fromISO(searchParams.get('startdatetime')),
    DateTime.fromISO(searchParams.get('enddatetime')),
    Object.entries(timeframes).map(entry => entry[1])
  )

  let saveCurrentURL = useCallback((favoriteName) => {
    dispatch({
      type: isSaved ? 'REMOVE_FAVORITE_URL' : 'ADD_FAVORITE_URL',
      payload: {
        date: DateTime.local().toISO(),
        name: favoriteName,
        to: url,
        timeframe: timeframe?.title ?? null
      }
    })
  }, [timeframe, isSaved, url, dispatch])

  return [ isSaved, saveCurrentURL ]
}

export function FavoriteButton () {
  let [ isSaved, saveCurrentURL ] = useFavoriteUrl()
  const [isOpen, setIsOpen] = useState(false)
  const [favoriteName, setFavoriteName] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const onClickFavorite = () => {
    if (isSaved) {
      saveCurrentURL(null)
    } else {
      setFavoriteName(document.title)
      setIsOpen(true)
    }
  }

  const closeFavorite = () => {
    setIsOpen(false)
    setFavoriteName(null)
    setErrorMessage(null)
  }

  const saveFavorite = () => {
    if (favoriteName.trim()) {
      saveCurrentURL(favoriteName)
      closeFavorite()
  } else {
      setErrorMessage('To create a new favorite, please enter a name')
    }
  }

  let icon = isSaved
    ? <FaStar />
    : <FaRegStar />

  return (
    <Dialog open={isOpen} onOpenChange={closeFavorite}>
      <DialogTrigger asChild>
        <Button
          icon={icon}
          onClick={onClickFavorite}
          title={isSaved ? 'Select to remove this report from your favorites list.' : 'Select to save this report to your favorites list.'}
        />
      </DialogTrigger>
      <DialogContent>
        <CardHeader display='flex' alignItems='center'>
          <DialogHeading flex={1}>Create new favorite</DialogHeading>
        </CardHeader>
        <CardContent>
          {errorMessage && (
            <Message type='error' title={<Text fontSize={6} mb={0}>{errorMessage}</Text>} />
          )}
          <Box>Save this dashboard and the currently applied filters as a favorite</Box>
          <Box className='more-settings small-label'>
            <MoreSettings>
              <MoreSettingsItem>
                <MoreSettingsItemLabel>
                  <Flex>
                    <Required />
                    <Text>Name</Text>
                  </Flex>
                </MoreSettingsItemLabel>
                <MoreSettingsItemControl>
                  <input
                    type='text'
                    value={favoriteName}
                    onChange={(e) => setFavoriteName(e.target.value)}
                  />
                </MoreSettingsItemControl>
              </MoreSettingsItem>
            </MoreSettings>
          </Box>
        </CardContent>
        <DialogFooter mr={3} mb={3}>
          <DialogCancel />
          <DialogConfirm onConfirm={saveFavorite} />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
