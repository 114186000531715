import { useActiveZonesByDataSource } from './use-active-zones'
import { useSpaceLayer } from './use-page-settings'

import { getAvailableSpaceLayers, defaultSpaceLayer } from '../utils/zone-utils'
import features from '../utils/features'

export function useActiveSpaceLayer (zones) {
  let activeZones = useActiveZonesByDataSource()
  let spaceLayer = useSpaceLayer()

  return getActiveSpaceLayer(zones ?? activeZones, spaceLayer)
}

export const getActiveSpaceLayer = (zones, spaceLayer = null) => {
  if (!features.enabled('space-layer-selection')) {
    return null
  }

  // set default space layer according to priority
  let availableSpaceLayers = getAvailableSpaceLayers(zones)
  if (!availableSpaceLayers.has(spaceLayer)) {
    return defaultSpaceLayer(availableSpaceLayers)
  }

  return spaceLayer
}
