import { DateTime } from 'luxon'
import { useCallback, useState, useRef } from 'react'
import { Flex, Text, SmallDetail, Icon } from '@lonerooftop/kitt-ui'
import { FaCheck, FaCaretDown, FaRegCalendar } from 'react-icons/fa'
import {
  useFloating,
  autoUpdate,
  flip,
  size,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
  useListNavigation,
  useTypeahead,
} from '@floating-ui/react'

import { SelectDateRangePicker } from './select-date-range'
import { DateTimeFormat } from '../datetime'
import { Button } from '../button'
import { Tooltip } from '../tooltip'

import { useStartDate, useEndDate, useSetDates } from '../../hooks/use-page-settings'
import { useStore, useSpaceHierarchy } from '../../hooks/use-store'

import { createInterval, createFirstOfMonth, createToday } from '../../utils/date-utils'
import { timeframes } from '../../utils/timeframes'

import { LOCALE } from '../../constants'

const dayFormat = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' }
const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' }
const yesterday = createToday().minus({ days: 1 }).toJSDate()

export function SelectQueryTimeFrameToday () {
  return (
    <Text fontSize={4}>
      Today
    </Text>
  )
}

export const HeaderTimeFrameDropdown = ({ timeframe }) => {
  const setDates = useSetDates()
  const { ids } = useSpaceHierarchy()
  const store = useStore()
  const dateRange = store.getMinMaxDate(ids)
  const minDate = DateTime.fromISO(dateRange[0])
  const maxDate = DateTime.fromISO(dateRange[1])
  const startdatetime = useStartDate()
  const enddatetime = useEndDate()
  const fromDate = DateTime.fromISO(startdatetime)
  const toDate = DateTime.fromISO(enddatetime).minus({ days: 1 })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null);
  const timeframes = useCreateTimeframe(timeframe)

  const items = timeframes.map((item, index) => {
    if (!item) {
      return null
    }
    if (item.title === 'customRange') {
      return {
        key: 'customRange',
        title: 'Date range',
      }
    }
    return {
      key: `${index}`,
      title: item.title,
      tooltipTitle: `${formatDate(item.start)}–${formatDate(item.end)}`,
      // exclude timeframes before and after the available dates, but still allow overlap
      disabled: (item.start !== undefined || item.end !== undefined) ? item.start < minDate || item.end > maxDate : true
    }
  })

  const { refs, context: floatingContext, floatingStyles } = useFloating({
    placement: 'bottom-start',
    open: isDropdownOpen,
    onOpenChange: setIsDropdownOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`
          })
        },
      })
    ]
  })
  const click = useClick(floatingContext)
  const dismiss = useDismiss(floatingContext)
  const role = useRole(floatingContext)
  const listRef = useRef([])
  const listNavigation = useListNavigation(floatingContext, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    enabled: !isDatePickerOpen
  })
  const itemTitles = items.map(item => (item && !item.disabled) ? item.title : null)
  const listContentRef = useRef(itemTitles)
  const isTypingRef = useRef(false)
  const typeahead = useTypeahead(floatingContext, {
    listRef: listContentRef,
    activeIndex,
    onMatch: setActiveIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping
    }
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role, listNavigation, typeahead])

  const setDateRange = useCallback(
    function onChange (key) {
      try {
        const { start, end } = timeframes[parseInt(key, 10)]
        setDates(start.toISODate(), end.toISODate())
      } catch (error) {
        throw new Error('Invalid timeframe key ' + key)
      }
    }
  , [ timeframes, setDates ])

  const setCustomDateRange = (range) => {
    if (!range?.from || !range?.to) {
      return
    }
    const start = DateTime.fromJSDate(range.from).toISODate()
    const end = DateTime.fromJSDate(range.to).plus({ days: 1 }).toISODate()
    if (start !== startdatetime || end !== enddatetime) {
      setDates(start, end)
    }
    setIsDropdownOpen(false)
  }

  const CustomDateRangeLabel = () => {
    const isSingleDay = (toDate.toJSDate() - fromDate.toJSDate()) < 86400000
    if (isSingleDay) {
      return <DateTimeFormat date={fromDate} format={dayFormat} />
    } else {
      return (
        <>
          <DateTimeFormat data-test-id={`timeframe-range-from=${fromDate.toISODate()}`} date={fromDate} format={dateFormat} />
          <SmallDetail> – </SmallDetail>
          <DateTimeFormat data-test-id={`timeframe-range-to=${toDate.toISODate()}`} date={toDate} format={dateFormat} />
        </>
      )
    }
  }

  const selectedIndex = timeframes.findIndex(timeframe =>
    timeframe?.start?.toISODate() === startdatetime
    && timeframe?.end?.toISODate() === enddatetime
  )
  const selectedItem = selectedIndex > -1 ? items[selectedIndex] : null
  const buttonLabel = selectedItem?.title || <CustomDateRangeLabel />

  return (
    <>
      <Button
        data-test-id='select-time'
        ref={refs.setReference}
        {...getReferenceProps({
          role: 'button'
        })}
        className='button button-dropdown'
      >
        <Flex alignItems='center'>
          <Text pr={2}>
            {buttonLabel}
          </Text>
          <Icon>
            <FaCaretDown className={isDropdownOpen ? 'rotatable rotate-180' : 'rotatable'} />
          </Icon>
        </Flex>
      </Button>
      {isDropdownOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={floatingContext} modal={false}>
            <div
              data-test-id='select-time-options'
              ref={refs.setFloating}
              style={{...floatingStyles}}
              {...getFloatingProps()}
            >
              <div className='dropdown-list'>
                {items.map((item, index) => {
                  if (item === null) {
                    return <hr aria-hidden='true' key={index} />
                  }

                  const isSelected = selectedItem?.key === item.key
                  const itemClass = `dropdown-list-option${isSelected ? ' active' : ''}${activeIndex ? ' active-index' : ''}`
                  const tabIndex = activeIndex === index ? 0 : -1
                  const itemRef = (node) => {
                    listRef.current[index] = node;
                  }

                  if (item.key === 'customRange') {
                    return (
                      <SelectDateRangePicker
                        key={index}
                        fromDate={fromDate.toJSDate()}
                        toDate={toDate.toJSDate()}
                        minDate={minDate.toJSDate()}
                        maxDate={maxDate.toJSDate() < yesterday ? maxDate.toJSDate() : yesterday}
                        setFromTo={setCustomDateRange}
                        isDatePickerOpen={isDatePickerOpen}
                        setIsDatePickerOpen={setIsDatePickerOpen}
                        trigger={(
                          <div
                            role='option'
                            tabIndex={tabIndex}
                            ref={itemRef}
                            className={itemClass}
                            onClick={() => {
                              setActiveIndex(index)
                              setIsDatePickerOpen(!isDatePickerOpen)
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter' || (event.key === ' ' && !isTypingRef.current)) {
                                event.preventDefault()
                                setActiveIndex(index)
                                setIsDatePickerOpen(!isDatePickerOpen)
                              }
                            }}
                          >
                            <Flex flexDirection='column'>
                              <Text pb={2}>
                                Date range
                              </Text>
                              <Flex alignItems='center' className='gap-2'>
                                <FaRegCalendar />
                                <Text fontSize={8}>
                                  <CustomDateRangeLabel />
                                </Text>
                                <FaCaretDown className={isDatePickerOpen ? 'rotatable rotate-180' : 'rotatable'} />
                              </Flex>
                            </Flex>
                          </div>
                        )}
                      />
                    )
                  }

                  return (
                    <div
                      key={index}
                      role='option'
                      tabIndex={tabIndex}
                      ref={itemRef}
                      className={itemClass}
                      onClick={() => {
                        setDateRange(item.key)
                        setIsDropdownOpen(false)
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' || (event.key === ' ' && !isTypingRef.current)) {
                          event.preventDefault()
                          setDateRange(item.key)
                          setIsDropdownOpen(false)
                        }
                      }}
                    >
                      {isSelected &&
                        <Icon>
                          <FaCheck />
                        </Icon>
                      }
                      <Tooltip
                        className='tooltip-text'
                        title={item.tooltipTitle}
                        placement='left'
                      >
                        {item.title}
                      </Tooltip>
                    </div>
                  )
                })}
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

function useCreateTimeframe (config) {
  let store = useStore()
  let [minDate, maxDate] = store.getMinMaxDate()
  let numberOfMonths = createInterval(minDate, maxDate).count('month') - 1
  let months = []

  // return months, sorted new -> old
  for (let month = 0; month < numberOfMonths; month++) {
    let start = createFirstOfMonth().minus({ month })
    let end = start.plus({ month: 1 })
    let interval = createInterval(start, end)
    months.push({
      title: start.toLocaleString({ month: 'long', year: 'numeric' }),
      start,
      end,
      interval
    })
  }

  let today = createToday()
  let working_days = Math.floor(today.daysInMonth / 7) * 5
  let min_day = Math.round(working_days * 0.9)

  // auto-select the current month when 90% of its working days have past, otherwise, select the previous one
  months.initial = months[today.day > min_day ? 0 : 1] || months[0] // fall-back when there's only 1 available months

  /** config can be:

  - a key from timeframes, e.g. 'today'
  - a hardcoded option value, e.g. 'months'
  - an array of the above
  **/
  if (Array.isArray(config)) {
    return config.flatMap(key => {
      switch (key) {
        case 'months':
          return months
        case 'customRange':
          return { title: 'customRange' }
        default:
          return timeframes[key] || null
      }
    })
  } else {
    return [config === 'months' ? months : timeframes[config]]
  }
}

const formatDate = new Intl.DateTimeFormat(LOCALE, DateTime.DATE_FULL).format
