import { DropdownMenu } from '../dropdown'
import { FilterTagContainer } from '../app/header'

import { formatList, formatString } from '../../utils/format'
import { splitZoneTag, zonesToTags } from '../../utils/tag-utils'
import { naturalSort } from '../../utils/sort'

import { useCallback } from 'react'
import { useTags, useSetTags } from '../../hooks/use-page-settings'
import { useActiveZonesByDataSourceSpaceLayerMetaData } from '../../hooks/use-active-zones'

export function SelectActiveQueryTags () {
  return (
    <SelectTags
      setTags={useSetTags()}
      activeTags={useTags()}
      zones={useActiveZonesByDataSourceSpaceLayerMetaData()}
      showInactiveItems={false}
    />
  )
}

export function SelectTags (props) {
  let { activeTags, setTags, zones, withLabel = false, showInactiveItems = true } = props

  let onChange = useCallback((next) => {
    if (!next) {
      return setTags(null)
    }

    if (activeTags.length === 0) {
      return setTags([next])
    }

    if (activeTags.some(tag => next.includes(splitZoneTag(tag)[0]))) {
      return setTags(activeTags.map(tag => {
        // remove the current selected tag in this group
        if (tag === next || splitZoneTag(tag)[0] === next) {
          return null
        } else if (next.includes(splitZoneTag(tag)[0])) {
          return next
        } else {
          return tag
        }
      }).filter(Boolean))
    } else {
      return setTags(activeTags.concat(next))
    }

  }, [ activeTags, setTags ])

  let { zonesByTag, tagHierarchy } = zonesToTags(zones)

  if (tagHierarchy.length === 0) {
    if (!showInactiveItems) {
      return null
    }
    const content = (
      <DropdownMenu disabled title='Select Function' onChange={() => {}} />
    )
    if (withLabel) {
      return (
        <FilterTagContainer title='Function'>
          {content}
        </FilterTagContainer>
      )
    }
    return content
  }

  return tagHierarchy.map(([ group, groupTags ]) => {
    let selected = activeTags.filter(tag => tag.startsWith(group))

    if (!showInactiveItems && selected.length === 0) {
      return null
    }

    let items = Array.from(groupTags).sort(naturalSort).map(tag => {
      let zones = zonesByTag.get(tag)
      let remaining = activeTags.length > 0
        ? zones.filter(zone => zone.tags.some(tag => activeTags.includes(tag)))
        : zones
      let [ key, value ] = splitZoneTag(tag)

      return {
        // if a tag is selected, the other tags in the same tag group should remain selectable
        // E.g. if Neighborhood A is selected, the user should be able to freely swith to another one
        disabled: selected.length > 0
          ? false :
          remaining.length === 0,
        key: tag,
        title: `${formatString(value)} (${zones.length})`,
        selectedTitle: `${formatString(key)}: ${formatString(value)}`,
        tooltipTitle: formatList(zones.map(z => z.name))
      }
    })

    const content = (
      <DropdownMenu
        items={items}
        onChange={onChange}
        selected={selected}
        title={`${selected.length ? '' : 'Select '}${formatString(group)}`}
        onClear={() => onChange(group)}
        data-test-id={`select-tag-${group}`}
      />
    )

    if (withLabel) {
      return (
        <FilterTagContainer key={group} title={formatString(group)}>
          {content}
        </FilterTagContainer>
      )
    }

    return content
  })
}
