import PropTypes from 'prop-types'
import { useState } from 'react'
import { FaCog, FaTimes } from 'react-icons/fa'
import { MdFilterList } from 'react-icons/md';
import { Flex, H6 } from '@lonerooftop/kitt-ui'
import { Button, ButtonPills } from '../button'
import { CardHeader, CardContent } from '../cards'
import { Label } from '../text'
import { FavoriteButton } from '../favorite'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeading,
  PopoverClose
} from '../popover'
import {
  Dialog,
  DialogTrigger,
  DialogHeading,
  DialogContent,
  DialogFooter,
  DialogCancel,
  DialogConfirm,
} from '../../components/dialog'
import { Tooltip } from '../tooltip'

import { SelectQueryCampus } from '../select/select-campus'
import { SelectQueryBuilding } from '../select/select-building'
import { SelectQueryFloor } from '../select/select-floor'
import { SelectQueryZone } from '../select/select-zone'
import { SelectQueryDataSource } from '../select/select-data-source'
import { SelectMetadata, SelectActiveQueryMetadata } from '../select/select-metadata'
import { SelectTags, SelectActiveQueryTags } from '../select/select-tag'
import { SelectQueryTimeFrameToday, HeaderTimeFrameDropdown } from '../select/select-time-frame'

import { usePageSettings } from '../../hooks/use-page-settings'
import { useStore } from '../../hooks/use-store'
import {
  useTags,
  useMetadata,
  useCapacity,
  useDataSource,
  useDataSourceTypes,
  useIds,
  useStartDate,
  useEndDate,
  useSetTagsAndMetadata,
} from '../../hooks/use-page-settings'
import { useActiveSpaceLayer } from '../../hooks/use-active-space-layer'
import { getActiveZonesByDataSourceSpaceLayerMetaData, useActiveZonesByDataSourceSpaceLayer } from '../../hooks/use-active-zones'
import { getSpaceHierarchy } from '../../hooks/use-store'

import { addOrRemove } from '../../utils/select-utils'

export function Header (props) {
  // the header accepts props but can fall back to page settings
  // this allows us to read header props from other components
  // see DEFAULT_SETTINGS in page for the initial values
  let { fixed } = usePageSettings()

  let {
    children,
    // time
    timeframe = fixed.timeframe,
    showDatePicker = fixed.showDatePicker,
    // space
    selectCampus = fixed.selectCampus,
    selectBuilding = fixed.selectBuilding,
    selectFloor = fixed.selectFloor,
    selectZone = fixed.selectZone,
    selectGroup = fixed.selectGroup,
    showDataSource = fixed.showDataSource,
    // additional settings
    showFavorite = fixed.showFavorite,
  } = props

  let config = {
    space: !!selectCampus,
    actions: (showDataSource || showFavorite),
    filter: !!selectGroup,
    time: (showDatePicker || timeframe),
    settings: (!!children || showDataSource),
  }

  return (
    <div data-test-id='app-header-filters' className='app-header'>
      <Flex flexWrap='wrap' flexGrow={1} className='gap-medium'>
        {config.filter &&
          <HeaderFilters />
        }
        {config.space &&
          <div data-test-id='select-space' className='select-space'>
            <ButtonPills>
            {selectCampus && <SelectQueryCampus />}
            {selectBuilding && <SelectQueryBuilding multiple={isMultiple(selectBuilding)} />}
            {selectFloor && <SelectQueryFloor multiple={isMultiple(selectFloor)} />}
            {selectZone && <SelectQueryZone multiple={isMultiple(selectZone)} />}
            </ButtonPills>
          </div>
        }
        {config.time &&
          <div className='select-time'>
            {timeframe === 'today' && <SelectQueryTimeFrameToday />}
            {timeframe && timeframe !== 'today' && <HeaderTimeFrameDropdown timeframe={timeframe} />}
          </div>
        }
        {config.filter &&
          <div className='select-filter'>
            <SelectActiveQueryTags />
            <SelectActiveQueryMetadata />
          </div>
        }
      </Flex>
      <Flex className='gap-medium'>
        {config.actions &&
          <div className='actions'>
            {showFavorite && <FavoriteButton />}
          </div>
        }
        {config.settings &&
          <div className='select-settings'>
            <HeaderSettings showDataSource={showDataSource}>{children}</HeaderSettings>
          </div>
        }
      </Flex>
    </div>
  )
}

Header.propTypes = {
  // valid predefined options for time frame selection
  // if not defined, the time frame selection won't be shown
  timeframe: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.bool
  ]),

  // whether to allow user to select custom start and end date; if no, they can still choose from timeframe options
  showDatePicker: PropTypes.bool,

  // for each space type, determine whether it's selectable and what is selected
  selectCampus: PropTypes.bool,
  selectBuilding: PropTypes.oneOf([true, false, 'multiple']),
  selectFloor: PropTypes.oneOf([true, false, 'multiple']),
  selectZone: PropTypes.oneOf([true, false, 'multiple']),
  selectGroup: PropTypes.bool,
  showDataSource: PropTypes.bool,
}

const HeaderFilters = () => {
  const [ isFiltersOpen, setIsFiltersOpen ] = useState(false)
  const [ updatedTags, setUpdatedTags ] = useState([])
  const [ updatedMetadata, setUpdatedMetadata ] = useState([])

  const activeTags = useTags()
  const metadata = useMetadata()
  const spaceHierarchy = getSpaceHierarchy(
    useStore(),
    useIds(),
    useStartDate(),
    useEndDate(),
    useCapacity(),
    useDataSource(),
    useDataSourceTypes(),
    updatedMetadata,
    updatedTags,
    useActiveSpaceLayer(),
  )
  const zones = Array.from(spaceHierarchy.zones.values())
  const setTagsAndMetadata = useSetTagsAndMetadata()

  const onOpenFilters = () => {
    setUpdatedTags(activeTags)
    setUpdatedMetadata(metadata)
    setIsFiltersOpen(true)
  }

  const onCloseFilters = () => {
    setUpdatedTags([])
    setUpdatedMetadata([])
    setIsFiltersOpen(false)
  }

  const onConfirm = () => {
    setTagsAndMetadata(updatedTags, updatedMetadata)
    onCloseFilters()
  }

  const onChangeMetadata = (next) => {
    const updated = addOrRemove(next, updatedMetadata) || []
    setUpdatedMetadata(updated)
  }

  return (
    <Dialog open={isFiltersOpen} onOpenChange={onCloseFilters}>
      <DialogTrigger asChild>
        <Button data-test-id='filters-button' aria-label='Filters' icon={<MdFilterList />} title='Filters' onClick={onOpenFilters} />
      </DialogTrigger>
      <DialogContent data-test-id='filters-dialog'>
        <CardHeader display='flex' alignItems='center'>
          <DialogHeading flex={1}>Filters</DialogHeading>
        </CardHeader>
        <CardContent data-test-id='select-filter'>
          <SelectTags
            activeTags={updatedTags}
            setTags={setUpdatedTags}
            zones={getActiveZonesByDataSourceSpaceLayerMetaData(useActiveZonesByDataSourceSpaceLayer(), updatedMetadata)}
            withLabel={true}
          />
          <SelectMetadata
            metadata={updatedMetadata}
            setZoneMetadata={onChangeMetadata}
            zones={zones}
            withLabel={true}
          />
        </CardContent>
        <DialogFooter mr={3} mb={3}>
          <DialogCancel />
          <DialogConfirm onConfirm={onConfirm} label='Confirm' />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export const FilterTagContainer = (props) => {
  const { title, children } = props
  return (
    <Flex flexDirection="column" mb={3}>
      <H6>
        {title}
      </H6>
      {children}
    </Flex>
  )
}

export function HeaderSettings (props) {
  const { children, showDataSource } = props

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button data-test-id='select-options' icon={<FaCog />}>
          Options
        </Button>
      </PopoverTrigger>
      <PopoverContent variant='primary' style={{ paddingBottom: 'var(--size-medium)'}}>
        <CardHeader display='flex' alignItems='center'>
          <PopoverHeading flex={1}>Configure this report</PopoverHeading>
          <PopoverClose variant='transparent' icon={<FaTimes />}></PopoverClose>
        </CardHeader>
        {showDataSource && <SelectQueryDataSource />}
        {children &&
          <div className='more-settings'>
            {children}
          </div>
        }
      </PopoverContent>
    </Popover>
  )
}

export function MoreSettings ({ children }) {
  return children
}

MoreSettings.propTypes = {
  children: PropTypes.node
}

export function MoreSettingsItem ({ children }) {
  return children
}

MoreSettingsItem.propTypes = {
  children: PropTypes.node
}

export function MoreSettingsItemHeader ({ children }) {
  return (
    <legend className='more-settings-header'>
      {children}
    </legend>
  )
}

export function MoreSettingsItemLabel ({ children, feature, title }) {
  return (
    <Tooltip
      className='more-settings-item-label label'
      data-feature={feature}
      as={Label}
      title={title}
      placement='left'
    >
      {children ?? (feature ? 'beta' : null)}
    </Tooltip>
  )
}

export function MoreSettingsItemControl (props) {
  let { children, title } = props
  let testId = props['data-test-id']

  return (
    <Tooltip
      className='more-settings-item-control'
      data-test-id={testId}
      title={title}
      placement='left'
    >
      {children}
    </Tooltip>
  )
}

function isMultiple (prop) {
  return prop === 'multiple'
}
