import { Flex } from '@lonerooftop/kitt-ui'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Logo } from './logo'
import { Menu, getPathnameStatus } from './menu'
import { AdminMenu, HelpMenu } from './top-header-menu'
import { PageContext } from '../../context'
import { MessagePermissionDenied } from '../message-permission-denied'
import { QUERY_PARAMS, TIMEFRAME, WEEKDAYS_SHORT } from '../../constants'
import { timeframes } from '../../utils/timeframes'
import { parseId } from '../../utils/space-utils'

let DEFAULT_SETTINGS = {
  initial: {
    [QUERY_PARAMS.AGGREGATION]: 'mean',
    [QUERY_PARAMS.CAPACITY]: 'default',
    [QUERY_PARAMS.FILTER_NO_CAPACITY]: 'enabled',
    [QUERY_PARAMS.IGNORE_MISSING_DATA]: 'enabled',
    [QUERY_PARAMS.STARTDATETIME]: timeframes.today.start.toISODate(),
    [QUERY_PARAMS.ENDDATETIME]: timeframes.today.end.toISODate(),
    [QUERY_PARAMS.OPENING_START]: 6,
    [QUERY_PARAMS.OPENING_END]: 24,
  },
  fixed: {
    timeframe: TIMEFRAME,
    showDatePicker: true,
    // space
    selectCampus: true,
    selectBuilding: true,
    selectFloor: false,
    selectZone: false,
    selectGroup: false,
    showDataSource: true,
    // additional settings
    showFavorite: true,
  }
}

export function Page (props) {
  let {
    children,
    settings,
    title,
    hideSidebar
  } = props

  console.time('Page')
  let location = useLocation()
  let access = getPathnameStatus(location.pathname)

  // set initial settings
  // (there seems to be a bug in useSearchParams() where the initial settings aren't always retained, e.g. when clicking on a menu entry that doesn't contain the dates)
  let initialSettings = {
    ...DEFAULT_SETTINGS.initial,
    ...settings?.initial ?? {}
  }

  // override fixed settings
  let fixedSettings = {
    ...DEFAULT_SETTINGS.fixed,
    ...settings?.fixed ?? {}
  }

  // no need to memoize since Page will only re-render when pathname or query changes
  let pageSettings = {
    initial: initialSettings,
    fixed: fixedSettings,
    query: useQuery(initialSettings, fixedSettings),
    setQuery: useSetQuery(),
  }

  useEffect(() => {
    if (document.title !== title) {
      document.title = title
    }
  }, [ title ])

  console.log('render Page', title, pageSettings)

  if (title === Page.defaultProps.title) {
    console.warn('Page is using the default title')
  }

  console.timeEnd('Page')

  return (
    <PageContext.Provider value={pageSettings}>
      <header className="top-header">
        <Logo />
        <Flex className="top-header-menus">
          <HelpMenu />
          <AdminMenu />
        </Flex>
      </header>
      {(access.disabled || access.hidden)
        ? <MessagePermissionDenied />
        : children}
      {!hideSidebar && (
        <aside className="app-sidebar">
          <Menu />
        </aside>
      )}
    </PageContext.Provider>
  )
}

Page.defaultProps = {
  title: 'HubStar Utilization',
  settings: DEFAULT_SETTINGS,
  hideSidebar: false
}

Page.propTypes = {
  title: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    initial: PropTypes.shape({
      [QUERY_PARAMS.IDS]: PropTypes.arrayOf(PropTypes.string),
      [QUERY_PARAMS.STARTDATETIME]: PropTypes.string,
      [QUERY_PARAMS.ENDDATETIME]: PropTypes.string,
    }),
    fixed: PropTypes.shape({
      [QUERY_PARAMS.IDS]: PropTypes.arrayOf(PropTypes.string),
      [QUERY_PARAMS.STARTDATETIME]: PropTypes.string,
      [QUERY_PARAMS.ENDDATETIME]: PropTypes.string,
    }),
  }).isRequired,
  hideSidebar: PropTypes.bool
}

function useQuery (initialSettings, fixedSettings) {
  let location = useLocation()
  return parseQuery(location, initialSettings, fixedSettings)
}

function useSetQuery () {
  let location = useLocation()
  let navigate = useNavigate()

  return (input, navigateOptions) => {
    let to = '?' + createSearchParams(input, location)
    console.debug('useSetQuery', { location, input, to })
    navigate(to, navigateOptions)
  }
}

/** Returns the selected valid API query parameters  **/
function parseQuery (location, initialSettings, fixedSettings) {
  console.debug('parseQuery', {location, initialSettings, fixedSettings})

  let searchParams = new URLSearchParams(location.search)
  let initial = Object.entries(initialSettings)
  if (initial.length > 0) {
    for (let [key, value] of initial) {
      if (searchParams.has(key)) {
        // nothing to do
        // console.debug('initial value already set, skipping', {key, value, existing: searchParams.get(key)})
        continue
      } else if (Array.isArray(value)) {
        // console.debug('set array', {key, value})
        value.forEach(v => {
          // console.debug('set array value', {key, v})
          searchParams.append(key, v)
        })
      } else {
        // console.debug('set initial value', {key, value})
        searchParams.set(key, value)
      }
    }
  }

  let fixed = Object.entries(fixedSettings)
  if (fixed.length > 0) {
    for (let [key, value] of fixed) {
      if (Array.isArray(value) && value.length > 0 && value.join('') !== searchParams.getAll(key).join('')) {
        searchParams.delete(key)
        value.forEach(v => {
          searchParams.append(key, v)
        })
      } else if (searchParams.get(key) !== value) {
        searchParams.set(key, value)
      }
    }
  }

  let getOpeningDays = days => WEEKDAYS_SHORT.filter(day => days.includes(day))
  let parsedQuery = {
    [QUERY_PARAMS.AGGREGATION]:         searchParams.get(QUERY_PARAMS.AGGREGATION),
    [QUERY_PARAMS.BOOKING_SOURCE]:      searchParams.getAll(QUERY_PARAMS.BOOKING_SOURCE),
    [QUERY_PARAMS.CAPACITY]:            searchParams.get(QUERY_PARAMS.CAPACITY),
    [QUERY_PARAMS.DATAFORMAT]:          searchParams.get(QUERY_PARAMS.DATAFORMAT),
    [QUERY_PARAMS.DATASOURCE]:          searchParams.get(QUERY_PARAMS.DATASOURCE),
    [QUERY_PARAMS.DATASOURCE_TYPE]:     searchParams.getAll(QUERY_PARAMS.DATASOURCE_TYPE),
    [QUERY_PARAMS.ENDDATETIME]:         searchParams.get(QUERY_PARAMS.ENDDATETIME),
    [QUERY_PARAMS.FILTER_COLUMNS]:      searchParams.getAll(QUERY_PARAMS.FILTER_COLUMNS),
    [QUERY_PARAMS.GROUP_BY]:            searchParams.get(QUERY_PARAMS.GROUP_BY),
    [QUERY_PARAMS.GROUP_COLUMNS]:       searchParams.getAll(QUERY_PARAMS.GROUP_COLUMNS),
    [QUERY_PARAMS.HIDDEN_COLUMNS]:      searchParams.getAll(QUERY_PARAMS.HIDDEN_COLUMNS),
    [QUERY_PARAMS.IDS]:                 searchParams.getAll(QUERY_PARAMS.IDS).map(parseId),
    [QUERY_PARAMS.INTERVAL]:            searchParams.get(QUERY_PARAMS.INTERVAL),
    [QUERY_PARAMS.OPENING_HOURS]:       searchParams.get(QUERY_PARAMS.OPENING_HOURS),
    [QUERY_PARAMS.OPENING_DAYS]:        getOpeningDays(searchParams.getAll(QUERY_PARAMS.OPENING_DAYS)),
    [QUERY_PARAMS.OPENING_START]:       parseInt(searchParams.get(QUERY_PARAMS.OPENING_START), 10),
    [QUERY_PARAMS.OPENING_END]:         parseInt(searchParams.get(QUERY_PARAMS.OPENING_END), 10),
    [QUERY_PARAMS.SHOW_COLUMNS]:        searchParams.getAll(QUERY_PARAMS.SHOW_COLUMNS),
    [QUERY_PARAMS.SORT_COLUMNS]:        searchParams.getAll(QUERY_PARAMS.SORT_COLUMNS),
    [QUERY_PARAMS.SPACE_LEVEL]:         searchParams.get(QUERY_PARAMS.SPACE_LEVEL),
    [QUERY_PARAMS.STARTDATETIME]:       searchParams.get(QUERY_PARAMS.STARTDATETIME),
    [QUERY_PARAMS.VISIBLE_COLUMNS]:     searchParams.getAll(QUERY_PARAMS.VISIBLE_COLUMNS),
    [QUERY_PARAMS.ZONE_METADATA]:       searchParams.getAll(QUERY_PARAMS.ZONE_METADATA),
    [QUERY_PARAMS.ZONE_TAG]:            searchParams.getAll(QUERY_PARAMS.ZONE_TAG),
    [QUERY_PARAMS.SPACE_SELECTION_TYPE]: searchParams.getAll(QUERY_PARAMS.SPACE_SELECTION_TYPE),

    // deprecated - these are only used for toggles in feature flags
    [QUERY_PARAMS.CAMPUS]:              searchParams.get(QUERY_PARAMS.CAMPUS),
    [QUERY_PARAMS.FILTER_NO_CAPACITY]:  searchParams.get(QUERY_PARAMS.FILTER_NO_CAPACITY),
    [QUERY_PARAMS.IGNORE_MISSING_DATA]: searchParams.get(QUERY_PARAMS.IGNORE_MISSING_DATA),
  }

  return new Map([
    // add existing and unparsed params
    ...searchParams.entries(),
    // override with parsed params
    ...Object.entries(parsedQuery)
  ])
}

function createSearchParams (input, location) {
  let searchParams = new URLSearchParams(location.search)
  Object.entries(input).forEach(([ key, value ]) => {
    let existing = searchParams.get(key)
    console.debug({ key, value, existing })

    // handle empty values
    if (value == null) {
      console.debug('updateQuery: delete null value', { key, value, existing })
      searchParams.delete(key)
    } else if (Array.isArray(value)) {
      console.debug('updateQuery: replace array value', { key, value })
      // we always replace the existing value(s)
      if (searchParams.has(key)) {
        searchParams.delete(key)
      }

      value.forEach(item => {
        searchParams.append(key, item)
        console.debug('adding', key, item, searchParams.getAll(key))
      })
    } else {
      // add or update key/values
      console.debug(`updateQuery: ${searchParams.has(key) ? 'update' : 'set'}`, { key, value, existing })
      searchParams.set(key, value)
    }
  })

  searchParams.sort()

  return searchParams
}
