import { useContext } from 'react'
import {
  useCapacity,
  useDataSource,
  useDataSourceTypes,
  useIds,
  useMetadata,
  useTags,
  useStartDate,
  useEndDate,
} from './use-page-settings'
import { useActiveSpaceLayer } from './use-active-space-layer'
import { StoreContext } from '../context'
import memo from 'memoize-one'
import { createZoneFilters } from '../utils/zone-utils'

export function useStore () {
  return useContext(StoreContext)
}

/** Returns an object that contains selected campus(es), building(s), floor(s) and zone(s) **/
export function useSpaces () {
  let store = useStore()
  let ids = useIds()
  let start = useStartDate()
  let end = useEndDate()

  return getSpaces(store, ids, start, end)
}

export let getSpaces = memo((store, ids, start, end) => {
  console.time('useSpaces')
  let campuses = new Map(),
      buildings = new Map(),
      floors = new Map(),
      zones = new Map()

  let selected = ids.filter(id => store.has(id))

  // auto-select only floor/building/campus
  if (selected.length === 0) {
    let available_campuses = store.campuses(null, start, end)
    if (available_campuses.length === 1) {
      let [campus] = available_campuses
      if (campus.buildings.length === 1) {
        let [building] = campus.buildings
        let currentFloors = store.floors(building.id, start, end)
        if (currentFloors.length === 1) {
          let [floor] = currentFloors
          selected.push(floor.id)
          floors.set(floor.id, floor)
        } else {
          selected.push(building.id)
        }
        buildings.set(building.id, building)
      } else {
        selected.push(campus.id)
      }
      campuses.set(campus.id, campus)
    }
  } else {
    selected.forEach(id => {
      let { campus, building, floor, zone } = store.getHierarchy(id, start, end)
      if (campus) {
        campuses.set(campus.id, campus)
      }
      if (building) {
        buildings.set(building.id, building)
      }
      if (floor) {
        floors.set(floor.id, floor)
      }
      if (zone) {
        zones.set(zone.id, zone)
      }
    })
  }

  // if only a campus is selected, select all buildings from that campus
  if (buildings.size === 0 && campuses.size === 1) {
    campuses.forEach(campus => {
      campus.buildings.forEach(
        building => {
          buildings.set(building.id, building)
      })
    })
  }

  let spaces = {
    ids: selected,
    spaces: selected
      .map(id => store.get(id, start, end))
      .filter(Boolean),
    campuses: Array.from(campuses.values()),
    buildings: Array.from(buildings.values()),
    floors: Array.from(floors.values()),
    zones: Array.from(zones.values()),
  }

  console.timeEnd('useSpaces')
  return spaces
})

/**
  * This takes selected ID's and returns spaces + parents + sub-spaces
  * The big difference with useSpaces is thus that this includes all sub-spaces,
  * while useSpaces only returns spaces that are actually selected.
  **/
export function useSpaceHierarchy () {
  return getSpaceHierarchy(
    useStore(),
    useIds(),
    useStartDate(),
    useEndDate(),
    useCapacity(),
    useDataSource(),
    useDataSourceTypes(),
    useMetadata(),
    useTags(),
    useActiveSpaceLayer(),
  )
}

export let getSpaceHierarchy = memo((store, ids, start, end, capacity, datasource, datasourceTypes, metadata, tags, spaceLayer) => {
  console.time('useSpaceHierarchy')

  let campuses = new Map(),
      buildings = new Map(),
      floors = new Map(),
      zones = new Map(),
      spaces = new Map()

  let addCampus = id => store.campuses(id, start, end).forEach(
    campus => campuses.set(campus.id, campus)
  )

  let addBuildings = id => store.buildings(id, start, end).forEach(
    building => buildings.set(building.id, building)
  )

  // @TODO: only return floors that exist with our zone filters?
  let addFloors = id => store.floors(id, start, end).forEach(
    floor => floors.set(floor.id, floor)
  )

  let filters = createZoneFilters(capacity, datasource, datasourceTypes, metadata, tags, spaceLayer)

  let addZones = id => store.zones(id, start, end).forEach(
    zone => {
      if (filters.every(zone)) {
        zones.set(zone.id, zone)
      }
    }
  )

  let addSpace = (space) => {
    spaces.set(space.id, space)

    switch (space.type) {
      case 'campus':
        campuses.set(space.id, space)
        addBuildings(space.id)
        addFloors(space.id)
        addZones(space.id)
        break

      case 'building':
        addCampus(space.campus_id)
        buildings.set(space.id, space)
        addFloors(space.id)
        addZones(space.id)
        break

      case 'floor':
        addCampus(space.campus_id)
        addBuildings(space.building_id)
        floors.set(space.id, space)
        addZones(space.id)
        break

      case 'zone':
        addCampus(space.campus_id)
        addBuildings(space.building_id)
        addFloors(space.floor_id)
        zones.set(space.id, space)
        break
      default:
        throw new Error('unknown space type: ' + space.type)
    }
  }

  let selected = ids.filter(id => store.has(id, start, end))
  // auto-select only floor/building/campus
  if (selected.length === 0) {
    let available_campuses = store.campuses(null, start, end)
    if (available_campuses.length === 1) {
      let [campus] = available_campuses
      if (campus.buildings.length === 1) {
        let [building] = campus.buildings
        let floors = store.floors(building.id, start, end)
        if (floors.length === 1) {
          let [floor] = floors
          addSpace(floor)
          selected.push(floor.id)
        } else {
          addSpace(building)
          selected.push(building.id)
        }
      } else {
        addSpace(campus)
        selected.push(campus.id)
      }
    }
  } else {
    selected.forEach((id) => {
      addSpace(store.get(id, start, end))
    })
  }

  console.timeEnd('useSpaceHierarchy')

  return {
    filters: { ids, start, end, capacity, datasource, datasourceTypes, metadata, tags, spaceLayer },
    spaces: Array.from(spaces.values()),
    ids: selected,
    campuses,
    buildings,
    floors,
    zones
  }
})
